import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import AboutHero from '~/components/Hero/AboutHero';
import Layout from '~/components/Layout';
import { AboutPageQuery } from '../graphql-types';

interface AboutPageProps {
  data: AboutPageQuery;
}

const About: FunctionComponent<AboutPageProps> = ({ data }): ReactElement => {
  const {
    title,
    aboutPage: { aboutHero },
    layouts,
  } = data.wpPage;

  return (
    <Layout>
      <SEO title={title} />

      <main>
        <AboutHero hero={aboutHero} />
        <FlexibleContent pageName="about" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      aboutPage {
        aboutHero {
          subheading
          heading
          content
          imageLeft {
            ...Image
          }
          imageRight {
            ...Image
          }
        }
      }

      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            image {
              ...Image
            }
            imagePosition
            backgroundColour
          }
          ... on WpPage_Layouts_Layouts_TextWithLogosGrid {
            subheading
            heading
            headingWidth
            content
            contentWidth
            textAlignment
            logoGrid {
              image {
                ...Image
              }
            }
            gridColumns
          }
          ... on WpPage_Layouts_Layouts_OurStory {
            # subheading
            heading
            content
          }
          ... on WpPage_Layouts_Layouts_PurposeStack {
            background
            subheading
            heading
            content
            featureBox {
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_OurValues {
            subheading
            heading
            content
            value {
              title
              content
            }
            image {
              ...Image
            }
            textBox {
              heading
              content
              link {
                title
                url
              }
            }
          }
          ... on WpPage_Layouts_Layouts_OurCustomers {
            subheading
            heading
            content
            logoGrid {
              image {
                ...Image
              }
            }
            cta {
              heading
              content
              button {
                title
                url
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
        }
      }
    }
  }
`;
