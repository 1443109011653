import React, { FunctionComponent } from 'react';
import Image from '~/components/Image';

interface AboutHeroProps {
  hero: {
    subheading: string;
    heading: string;
    content: string;
    imageLeft: string;
    imageRight: string;
  };
}

const AboutHero: FunctionComponent<AboutHeroProps> = ({ hero }) => {
  return (
    <section className="my-12 md:my-24">
      <div className="container">
        <div className="font-medium text-red">{hero.subheading}</div>
        <h1
          className="max-w-md md:max-w-2xl"
          dangerouslySetInnerHTML={{
            __html: hero.heading,
          }}
        />
        <div
          className="max-w-md"
          dangerouslySetInnerHTML={{
            __html: hero.content,
          }}
        />
      </div>
      <div className="md:container mt-12 md:mt-16">
        <div className="md:grid grid-cols-2 gap-6 mt-8 md:mt-12">
          <div className="w-full md:pt-12">
            <Image
              data={hero?.imageLeft}
              loading="eager"
              className="w-full object-cover h-full"
            />
          </div>
          <div className="w-7/12 md:w-full md:mb-12 -mt-20 mr-4 md:mr-0 ml-auto">
            <Image
              data={hero?.imageRight}
              loading="eager"
              className="w-full object-cover border-white border-8 border-solid md:border-0"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
